<template>
    <div class="token-editor-wrap">
        <div
            v-if="toggleShow"
            v-outside-click="closeMenu"
            class="token-add"
            :class="{ 'token-add-menu-open': menuOpen }">
            <div class="token-menu-wrap">
                <styled-tooltip
                    :value="tooltipVisible"
                    position="top">
                    <template
                        v-if="tooltipVisible"
                        #content>
                        <div class="text-center">
                            <span class="copy-limit-reached">Copy Limit Reached</span><br>
                            You’ve reached the maximum character limit. To insert a variable, please reduce your text length and try again.
                        </div>
                    </template>
                    <button @click="menuOpen = !menuOpen">
                        <icon
                            name="indicator-add"
                            size="20"
                            :color="colors.gray" />
                    </button>
                </styled-tooltip>
                <div
                    v-if="menuOpen"
                    class="token-menu">
                    <div class="token-menu-inner">
                        <a
                            v-for="token in tokens"
                            :key="token.field"
                            href="#"
                            @click.prevent="insertToken(token)">
                            {{ token.label }}
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div
            ref="editable"
            class="token-editor"
            contenteditable
            @click="handleClick"
            @keydown="handleKeyDown"
            @input="onInput" />
    </div>
</template>


<script>
import Icon from '@/components/globals/Icon.vue';
import colors from '@/helpers/colors';
import StyledTooltip from '@/components/globals/StyledTooltip.vue';

export default {
    components: {
        Icon,
        StyledTooltip,
    },
    props: {
        value: {
            type: String,
            required: true,
        },
        tokens: {
            type: Array,
            required: true,
        },
        maxLength: {
            type: Number,
            default: null
        },
        toggleShow: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            colors,
            menuOpen: false,
            selectedToken: null,
            tooltipVisible: false, // Tooltip visibility state
        };
    },
    watch: {
        value: {
            handler(newVal) {
                if (this.$refs.editable) {
                    const currentHtmlValue = this.editorToString();
                    if (newVal !== currentHtmlValue) {
                        this.$refs.editable.innerHTML = this.stringToEditor(newVal);
                    }
                    this.updateLength();
                    if (!this.$refs.editable.innerHTML.trim()) {
                        this.$refs.editable.focus(); // Focus on the editable area if it's empty
                    }
                }
            },
            immediate: true
        }
    },
    mounted() {
        if (this.$refs.editable) {
            this.$refs.editable.innerHTML = this.stringToEditor(this.value);
            this.updateLength();
            if (!this.$refs.editable.innerHTML.trim()) {
                this.$refs.editable.focus(); // Focus on the editable area if it's empty
            }
        }
    },
    methods: {
        onInput() {
            this.updateLength();
            if (this.$refs.editable.innerHTML === '') {
                this.$refs.editable.innerHTML = '&nbsp;'; // Ensure the editor is not empty
            }
            this.emitInput();
        },
        updateLength() {
            const currentLength = this.editorToString().length;
            this.$emit('update-length', currentLength);
        },
        emitInput() {
            const output = this.editorToString();
            if (this.maxLength && output.length > this.maxLength) {
                this.showTooltip(); // Show the tooltip when the max length is exceeded
                const truncatedOutput = output.substring(0, this.maxLength);
                this.$refs.editable.innerHTML = this.stringToEditor(truncatedOutput);
                this.$emit('input', truncatedOutput);
                this.$emit('update-length', this.maxLength);
            } else {
                this.tooltipVisible = false; // Hide the tooltip if within limit
                this.$emit('input', output);
            }
        },
        insertToken(token) {
            const tokenString = `${token.field}`;
            const currentLength = this.editorToString().length;

            if (this.maxLength && currentLength + tokenString.length > this.maxLength) {
                this.showTooltip(); // Show tooltip if exceeding length
                return;
            }

            this.menuOpen = false;
            this.placeToken(token);
            this.emitInput();
        },
        showTooltip() {
            this.tooltipVisible = true; // Show the tooltip
            setTimeout(() => {
                this.tooltipVisible = false; // Hide the tooltip after 3 seconds
            }, 3000);
        },
        closeMenu() {
            this.menuOpen = false;
        },
        handleKeyDown(event) {
            const keycode = event.keyCode;

            if (this.maxLength && this.editorToString().length >= this.maxLength && ![8, 37, 38, 39, 40, 46].includes(keycode)) {
                event.preventDefault();
                return;
            }
        },
        handleClick(event) {
            if (!this.$refs.editable.innerHTML.trim()) {
                this.$refs.editable.focus(); // Focus if the editor is empty
            }
            if (event.target.classList.contains('token-editor-token')) {
                event.target.classList.add('highlight');
                this.selectedToken = event.target;
            } else if (this.selectedToken) {
                this.selectedToken.classList.remove('highlight');
            }
        },
        placeToken(token) {
            const tokenElem = this.createTokenElem(token);
            const selection = window.getSelection();
            let range;

            // If the cursor is in the area place the token at it
            if (selection.focusNode.parentElement === this.$refs.editable) {
                range = selection.getRangeAt(0);
                range.deleteContents();

                const spaceNode = document.createTextNode(' ');
                range.insertNode(spaceNode);
                range.insertNode(tokenElem);
            } else {
                range = document.createRange();
                range.selectNodeContents(this.$refs.editable);
                range.collapse(false);

                selection.removeAllRanges();
                selection.addRange(range);

                range.insertNode(tokenElem);
            }

            this.emitInput();
        },
        createTokenElem(token) {
            const tokenElem = document.createElement('span');
            tokenElem.className = 'token-editor-token';
            tokenElem.dataset.field = token.field;
            tokenElem.textContent = token.label;
            tokenElem.setAttribute('contenteditable', false);

            return tokenElem;
        },
        editorToString() {
            if (!this.$refs.editable) {
                return ''; // Return an empty string if the element is not yet available
            }

            let string = '';
            this.$refs.editable.childNodes.forEach((node) => {
                if (node instanceof HTMLElement && node.classList.contains('token-editor-token')) {
                    string += node.dataset.field;
                } else if (node instanceof HTMLElement && node.nodeName === 'BR') {
                    string += '\n';
                } else {
                    string += node.textContent;
                }
            });
            return string;
        },
        stringToEditor(string) {
            const tokenString = this.tokens
                .map((token) => {
                    return token.field.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
                })
                .join('|');

            // Create regex using the keys of the replacement object.
            const regex = new RegExp(`(${tokenString})`, 'g');

            return string
                .replace(regex, (original, field) => {
                    const token = this.tokens.find((token) => token.field == field);

                    if (!token) {
                        return original;
                    }

                    return `<span class="token-editor-token" data-field="${field}" contenteditable="false">${token.label}</span>`;
                })
                .replace(/\n/g, '<br />');
        },
    },
};
</script>
<style lang="scss">
.token-editor-token {
    background-color: $gray-dark;
    color: $white;
    border-radius: 2px;
    padding: 2px 5px;
    text-transform: uppercase;
    font-size: 0.8rem;
    line-height: 1.2rem;
    display: inline-block;
    transition: background-color 0.05s ease-in-out;

    &::selection {
        background: transparent;
        color: $white;
    }

    &.highlight {
        background-color: $black;
    }
}

.token-editor-wrap {
    position: relative;

    &:hover {
        .token-add {
            opacity: 1;
        }
    }
}

.token-editor {
    min-height: 1.5em; /* Ensure there's always some height */
    white-space: pre-wrap;
    display: inline-block;
    cursor: text; /* Show the text cursor */
    &:focus {
        outline: none;
    }
}

.token-add {
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 100;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    &.token-add-menu-open {
        button {
            svg {
                transform: rotate(135deg);
            }
        }
    }
}

.token-menu-wrap {
    position: relative;
    top: 0;
    right: 0;

    button {
        svg {
            transition: transform 0.15s ease-in-out;
        }
    }

    .token-menu {
        position: absolute;
        right: 0;
        padding-top: 8px;

        &::after {
            content: '';
            position: absolute;
            bottom: calc(100% - 8px);
            z-index: 100;
            right: 5px;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent $gray-darker transparent;
        }

        .token-menu-inner {
            max-height: 210px;
            overflow-y: scroll;
        }

        a {
            display: block;
            color: $white;
            border-bottom: 1px solid $gray-light;
            text-decoration: none;
            white-space: nowrap;
            font-size: 0.85rem;
            padding: 5px 10px;
            transition: background-color 0.2s ease-in-out;
            background-color: $gray-darker;

            &:hover {
                background-color: $gray;
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }


}
</style>
<style scoped>
.copy-limit-reached {
    color: red !important;
    font-size: 1.2rem !important;
    font-weight: bold !important;
}
</style>

