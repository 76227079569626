export const adCopyTokens = [{
    field: '<%$dealer_name%>',
    label: 'Dealer Name',
    description: 'The dealer name associated with the vehicle the user is inquiring about'
}];
export const AIATokens = [{
    field: '{{vehicle.price}}',
    label: 'Vehicle Price',
    description: 'The price of the vehicle'
},{
    field: '{{vehicle.price strip_zeros}}',
    label: 'Vehicle Price Strip Zeros',
    description: 'The price of the vehicle with zeros stripped'
},{
    field: '{{vehicle.title}}',
    label: 'Vehicle Title',
    description: 'The price of the vehicle'
},{
    field: '{{vehicle.dealer_name}}',
    label: 'Vehicle Dealer Name',
    description: 'The price of the vehicle'
}];

export const tokens = [{
    field: '$first_name',
    label: 'First Name',
    description: 'The first name of the messenger user'
},{
    field: '$full_name',
    label: 'Full Name',
    description: 'The full name of the messenger user'
},{
    field: '$dealer_name',
    label: 'Dealer Name',
    description: 'The dealer name associated with the vehicle the user is inquiring about'
},{
    field: '$dealer.address',
    label: 'Dealer Address',
    description: 'The street address of the dealer'
},{
    field: '$dealer.city',
    label: 'Dealer City',
    description: 'The city of the dealer'
},{
    field: '$dealer.state',
    label: 'Dealer State',
    description: 'The state of the dealer'
},{
    field: '$dealer.zip',
    label: 'Dealer Zip',
    description: 'The zip code of the dealer'
},{
    field: '$dealer.phone',
    label: 'Dealer Phone',
    description: 'The phone number of the dealer'
},{
    field: '$vehicle.make_name',
    label: 'Vehicle Make',
    description: 'The make of the vehicle'
},{
    field: '$vehicle.model_name',
    label: 'Vehicle Model',
    description: 'The model of the vehicle'
},{
    field: '$vehicle.trim_name',
    label: 'Vehicle Trim',
    description: 'The trim of the vehicle'
},{
    field: '$vehicle.mileage',
    label: 'Vehicle Mileage',
    description: 'The mileage/odometer on the vehicle'
},{
    field: '$vehicle.price',
    label: 'Vehicle Price',
    description: 'The price of the vehicle'
},{
    field: '$vdp_url',
    label: 'VDP URL',
    description: 'The the website VDP of the vehicle'
},{
    field: '$vehicle_location.city',
    label: 'Vehicle City',
    description: 'The city of the vehicle'
},{
    field: '$vehicle.state',
    label: 'Vehicle State',
    description: 'The state of the vehicle'
},{
    field: '$vehicle_location.zip',
    label: 'Vehicle Zip',
    description: 'The zip code of the vehicle'
}];
